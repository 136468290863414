import { getCountryKey, getLang, getLanguageCode } from 'helpers/country'
import { recordTime } from 'helpers/analizeTools'
// import requestService from 'helpers/fetch'
import configuredAxios from 'helpers/configuredAxios'

const getConfig = (countryKey, lang) => {
  const stop = recordTime('config skills request')
  // const request = requestService('config', 'GET')
  const axios = configuredAxios('config')
  return axios.get(`/skill/lists?language_code=${lang}`).then((result) => {
    stop()
    return result.data
  })
}
const fetchSkillConfigService = (defaultLang) => {
  const [countryKey, lang] = [
    getCountryKey(),
    getLanguageCode(defaultLang) || getLanguageCode(getLang())
  ]
  return getConfig(countryKey, lang).then((res) => {
    const result = res?.data || []
    return result
  })
}

export { fetchSkillConfigService }
