/* Helpers */
import { getCookie } from 'helpers/cookies'

export const authPathToOldProject = ( redirectUrl) => {
  const sessionid = getCookie('sessionid')
  const isAppRedirectModalClosed = getCookie('isAppRedirectModalClosed') ? 'true' : 'false'

  redirectUrl = `${process.env.OLD_PROJECT_URL}/jobseeker-login-redirect?redirectUrl=${redirectUrl}`
  const delimiter = '/jobseeker-login-redirect?redirectUrl='
  const redirectArray = redirectUrl.split(delimiter)

  // Encode url params 
  if (redirectArray.length === 2) {
    const queryParam = redirectArray[1]
    const encodedQueryParam = encodeURIComponent(queryParam)
    const encodedRedirect = ''
    encodedRedirect.concat(redirectArray[0], delimiter, encodedQueryParam)
    redirectUrl = redirectArray[0] + delimiter + encodedQueryParam
  }

  if (sessionid) {
    return `${redirectUrl}&sessionid=${sessionid}&isAppRedirectModalClosed=${isAppRedirectModalClosed}`
  }

  return `${redirectUrl}&isAppRedirectModalClosed=${isAppRedirectModalClosed}`
}
