import configuredAxios from 'helpers/configuredAxios'

const addUserPreferencesService = (payload) => {
  const axios = configuredAxios('jobseeker', '')

  return axios.post('/job-preferences/update', { ...payload.preferences })
}
const deleteUserPreferencesService = (payload) => {
  const axios = configuredAxios('jobseeker', '')

  return axios.delete(`/job-preferences/${payload.preferenceId}/delete`)
}

const updateUserPreferencesService = (payload) => {
  const axios = configuredAxios('jobseeker', '')

  return axios.patch(`/job-preferences/${payload.preferenceId}/update`, payload.params)
}

const createUserPreferencesService = (payload) => {
  const axios = configuredAxios('jobseeker', '')

  return axios.post(`/job-preferences/create`, payload.params)
}

const assistTrigger = () => {
  const axios = configuredAxios('jobseeker', '',)

  return axios.post(`/sg-spring-event/assist-trigger`, {
    onboarded_method: "web_complete_profile"
  })
}

export {
  addUserPreferencesService,
  deleteUserPreferencesService,
  updateUserPreferencesService,
  createUserPreferencesService,
  assistTrigger
}
