import styled from '@emotion/styled'
import React from 'react'

const CircularProgress = () => {
  return (
    <CircularStyle>
      <span className='loading-wrapper'>
        <svg viewBox="22 22 44 44">
          <circle stroke='currentColor' cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
        </svg>
      </span>
    </CircularStyle>
  )
}

export default CircularProgress


const CircularStyle = styled.span`
  display: inline-block;
  color: #1976d2;
  width: 24px;
  height: 24px;
  animation: animation-loading-1 1.4s linear infinite;

  svg {
    display: block;
  }

  circle {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    animation: animation-circle-2 1.4s ease-in-out infinite;
  }

  @keyframes animation-loading-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animation-circle-2 {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }

`