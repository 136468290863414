// @ts-check
// import Cookies from 'universal-cookie'
import { toLower } from 'lodash-es'

export const userKey = 'user'
export const redirectUrl = 'redirectUrl'
export const sourceKey = 'source' // track source
export const configKey = 'geoConfiguration'

// session keys
export const cfKey = 'cfKey'

// const cookies = new Cookies()

export const isServer = typeof window === 'undefined'

export const setCookie = (cookieName, data, expireTime) => {
  if (typeof window === 'undefined') {
    return;
  }

  let dataStr = data;
  // 检查data是否是对象，如果是，则转换为JSON字符串
  if (typeof data === 'object' && data !== null) {
    dataStr = JSON.stringify(data);
  }

  // const date = new Date();
  // date.setTime(date.getTime() + (expireTime * 24 * 60 * 60 * 1000))
  // const expires = expireTime ? "; expires=" + date.toUTCString() : ''

  const defaultExpireDay = 30; // 设置默认过期时间
  const date = new Date();
  date.setTime(date.getTime() + (defaultExpireDay * 24 * 60 * 60 * 1000));

  const expiresTime = expireTime
    ? new Date(expireTime)
    : new Date(date)
  const expires = '; expires=' + expiresTime.toUTCString()
  document.cookie = cookieName + "=" + (dataStr || "") + expires + "; path=/";
}

export const getCookie = cookieName => {
  if (typeof window === 'undefined') {
    // TODO: 临时处理方案，需要使用的地方区分。
    const { cookies } = require('next/headers')
    const result = cookies()?.get(cookieName)?.value;
    return result
  }
  const nameEQ = cookieName + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim(); // 使用trim()方法来清除前导和尾随空格
    if (c.indexOf(nameEQ) == 0) {
      const value = c.substring(nameEQ.length, c.length);

      try {
        // 尝试将值解析为JSON对象
        const parsedValue = JSON.parse(value);
        return typeof parsedValue == 'object' ? parsedValue : value; // 如果成功，返回解析后的对象
      } catch (e) {
        // 如果解析失败，说明值不是有效的JSON字符串，直接返回原始字符串
        return value;
      }
    }
  }
  return null;
}

export const removeCookie = (cookieName) => {
  if (typeof window === 'undefined') {
    return
  }

  // 解析当前的所有cookie
  const cookies = document.cookie.split(';');

  // 遍历所有cookie，找到需要删除的cookie，并将其过期时间设置为过去的时间
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
    if (name === cookieName) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT';
      break;
    }
  }
}

export const setCookieWithExpiry = (cookieName, data, timeToExpiry) => {
  return setCookie(cookieName, data, timeToExpiry)
}

export const setSourceCookie = (data) => {
  setCookie(sourceKey, toLower(data))
}

export const getSourceCookie = () => {
  return getCookie(sourceKey) || 'job_search'
}
/**
 * remove the user's data in browser
 */
export const removeUserCookie = () => {
  removeCookie(userKey)
  removeCookie('sessionid')
  removeCookie('im_auth_sid')
}

export const handleUserCookiesConfig = (user) => {
  return {
    active_key: user?.active_key,
    id: user?.id,
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone_num: user?.phone_num,
    is_mobile_verified: user?.is_mobile_verified,
    avatar: user?.avatar,
    additional_info: user?.additional_info,
    is_email_verify: user?.is_email_verify,
    notice_period_id: user?.notice_period_id,
    is_profile_completed: user?.is_profile_completed,
    longitude: user?.longitude,
    latitude: user?.latitude
  }
}
