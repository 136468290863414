import configuredAxios from 'helpers/configuredAxios'

const fetchJobAlertsListService = () => {
  const axios = configuredAxios('job', '')
  return axios.get(`/job-alerts`)
}

const fetchJobAlertsListV2Service = (params) => {
  const axios = configuredAxios('job', '')
  return axios.get(`/v2/job-alerts`, { params })
}

const fetchPublicJobAlertsListService = (payload) => {
  const axios = configuredAxios('job')
  return axios.get(`/public-job-alerts?code=${payload.code}`)
}

export { fetchJobAlertsListService, fetchJobAlertsListV2Service, fetchPublicJobAlertsListService }
