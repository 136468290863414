import configuredAxios from 'helpers/configuredAxios'
import { isMobileDevice } from 'helpers/utilities'

const fetchUserOwnDetailService = () => {
  const axios = configuredAxios('jobseeker', '')
  const isMobile = isMobileDevice()
  return axios.get(`/me?source=${isMobile ? 'mobile_web' : 'web'}`)
}

export { fetchUserOwnDetailService }
