import configuredAxios from 'helpers/configuredAxios'

const addUserWorkExperienceService = (payload) => {
  console.log('------payload--666---', payload)
  const axios = configuredAxios('jobseeker', '')

  return axios.post('/work-experiences/create', { ...payload.workExperience })
}

export { addUserWorkExperienceService }
