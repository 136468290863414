const adjustUrlList = [
  // H5
  {
    link: 'https://cyyj.adj.st/bossjob.ph/home?adj_t=1d2kydrr',
    adjustConf: 'https://suite.adjust.com/custom-links/1d2kydrr/campaign/link-urls',
    comment: '全局浮动下载横幅'
  },
  {
    link: "https://cyyj.adj.st/bossjob.ph/home?adj_t=1dwcavke",
    adjustConf: 'https://suite.adjust.com/custom-links/1dwcavke/campaign/link-urls',
    comment: 'APP落地页下载'
  },
  {
    link: "https://cyyj.adj.st/bossjob.ph/home?adj_t=1dfrhvsd",
    adjustConf: 'https://suite.adjust.com/custom-links/1dfrhvsd/campaign/link-urls',
    comment: 'APP落地页Footer'
  },

  // Web - 求职端
  {
    link: "https://cyyj.adj.st/bossjob.ph/home?adj_t=1dvjl1h5",
    adjustConf: 'https://suite.adjust.com/custom-links/1dvjl1h5/campaign/link-urls',
    comment: '主页横幅'
  },
  {
    link: "https://cyyj.adj.st/bossjob.ph/home?adj_t=1de65z8d",
    adjustConf: 'https://suite.adjust.com/custom-links/1de65z8d/campaign/link-urls',
    comment: '全局Footer'
  },
  {
    link: "https://cyyj.adj.st/bossjob.ph/home?adj_t=1davs0lz",
    adjustConf: 'https://suite.adjust.com/custom-links/1davs0lz/campaign/link-urls',
    comment: 'APP落地页'
  },
  {
    link: 'https://cyyj.adj.st/bossjob.ph/home?adj_t=1dgd9288',
    adjustConf: 'https://suite.adjust.com/custom-links/1dgd9288/campaign/link-urls',
    comment: 'APP落地页footer'
  },
  {
    link: 'https://cyyj.adj.st/bossjob.ph?adj_t=1dyxdqvp&adj_deep_link=bossjob://bossjob.ph/job-details?jobId=',
    adjustConf: 'https://suite.adjust.com/custom-links/1dyxdqvp/campaign/link-urls',
    comment: '工作列表'
  },
  {
    link: 'https://cyyj.adj.st/bossjob.ph/chat-home?adj_t=1dxvmfqw',
    adjustConf: 'https://suite.adjust.com/custom-links/1dxvmfqw/campaign/link-urls',
    comment: 'Chat 聊天窗口'
  }
]

export default adjustUrlList