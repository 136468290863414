import { call, put, take } from 'redux-saga/effects'
import {
  handleUserCookiesConfig,
  setCookie,
  userKey
} from 'helpers/cookies'

import { JOBBSEEKERS_LOGIN_REQUEST } from 'store/types/auth/jobseekersLogin'

import { jobbseekersLoginSuccess, jobbseekersLoginFailed } from 'store/actions/auth/jobseekersLogin'

import { authenticationJobseekersLogin } from 'store/services/auth/jobseekersLogin'
import { checkErrorCode } from 'helpers/errorHandlers'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'

import * as fbq from 'lib/fpixel'

function* loginReq(actions) {
  try {
    const response = yield call(authenticationJobseekersLogin, actions.payload)

    if (response.status >= 200 && response.status < 300) {
      yield put(jobbseekersLoginSuccess(response.data))
      const loginData = response.data.data
      const userCookie = handleUserCookiesConfig(loginData)
      yield call(setCookie, userKey, userCookie)

      // Send register event (First time login user)
      if (
        process.env.ENV === 'production' &&
        loginData.is_new_account &&
        typeof window !== 'undefined'
      ) {
        // Facebook Pixel
        if (window.fbq) {
          yield fbq.event('sign_up', {
            user_id: loginData?.id
          })
        }

        // Google analytic Event
        if (window.gtag) {
          yield window?.gtag?.('event', 'sign_up', {
            user_id: loginData?.id,
            email: loginData?.email
          })
        }

        // Tiktok Pixel
        if (window.ttq) {
          yield window.ttq.track('CompleteRegistration', {
            user_id: loginData?.id,
            email: loginData?.email
          })
        }
      }
    }
  } catch (err) {
    const isServerError = checkErrorCode(err)
    if (isServerError) {
      yield put(
        displayNotification({
          open: true,
          severity: 'error',
          message:
            'We are sorry. Something went wrong. There was an unexpected server error. Try refreshing the page or contact support@bossjob.com for assistance.'
        })
      )
    } else {
      const statusCode = err.response.status
      let errorMessage = ''

      if (statusCode === 401 || statusCode === 422) {
        errorMessage = 'invalid credential'
      } else if (statusCode === 403) {
        errorMessage = 'account suspended'
      }

      if (errorMessage) {
        yield put(jobbseekersLoginFailed(errorMessage))
      } else {
        yield put(jobbseekersLoginFailed(err.response))
      }
    }
  }
}

export default function* jobseekersLoginSaga() {
  while (true) {
    const action = yield take(JOBBSEEKERS_LOGIN_REQUEST)
    yield loginReq(action)
  }
}
