import configuredAxios from 'helpers/configuredAxios'

const fetchChattedJobs = (payload) => {
  const { page } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.get(`/chats/jobseekers/chatted-jobs?page=${page}&size=15`)
}

const fetchResume = (payload) => {
  const { page } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.get(`/jobseekers/resume-exchanged-jobs?page=${page}&size=15`)
}

const fetchResumeContact = () => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.get(`/jobseekers/contact-exchanged-jobs`)
}

const fetchSaved = (payload) => {
  const { page } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('job', endpointType, false)
  return axios.get(`/saved-jobs?page=${page}&size=15`)
}

const fetchViewed = (payload) => {
  const { page } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('job', endpointType, false)
  return axios.get(`/viewed-jobs?page=${page}&size=15`)
}

const updateNoticePeriod = ({ id }) => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.patch(`/me`, { notice_period_id: id })
}

const updateUserSgId = ({ id }) => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.patch(`/me`, { user_identity_id: id })
}

const fetchPersonalInfo = () => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.get(`/personal-info?interview_filter=all`)
}

const fetchRecruiters = () => {
  const endpointType = 'protected'
  const axios = configuredAxios('recruiters', endpointType, false)
  return axios.get(`/saved-me-recruiters?page=1&size=6`)
}

const fetchViewedRcruiters = () => {
  const endpointType = 'protected'
  const axios = configuredAxios('recruiters', endpointType, false)
  return axios.get(`/viewed-me-recruiters?page=1&size=6`)
}

const fetchInterviews = ({ page }) => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.get(`jobseekers/interviews?page=${page}&size=15`)
}

const fetchResumes = () => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.get(`/resumes`)
}

const fetchDeleteResumes = ({ id }) => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.delete(`/resumes/${id}/delete`)
}

const fetchRenameResumes = (payload) => {
  const { id } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  const data = {
    filename: payload.filename
  }
  return axios.put(`/resumes/${id}/rename`, data)
}

const fetchSendResumeEmail = (payload) => {
  const { id } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  const data = {
    email: payload.email,
    resume_id: id
  }
  return axios.post(`/send-resume-email`, data)
}

const fetchCheckChats = (payload) => {
  const { ids } = payload || {}
  const endpointType = 'protected'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.get(`chats/check-exists/jobseekers?recruiter_ids=${ids}`)
}
const fetchQueryOnlineStatus = (payload) => {
  const { ids } = payload || {}
  const endpointType = 'public'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.post(`/chats/query-online-status`, {
    "user_ids": ids,
    "role": "recruiter"
  })
}

export {
  fetchChattedJobs,
  fetchSaved,
  fetchResume,
  fetchResumeContact,
  fetchViewed,
  updateNoticePeriod,
  fetchPersonalInfo,
  fetchRecruiters,
  fetchViewedRcruiters,
  fetchInterviews,
  fetchResumes,
  fetchDeleteResumes,
  fetchCheckChats,
  fetchRenameResumes,
  fetchSendResumeEmail,
  fetchQueryOnlineStatus,
  updateUserSgId
}
