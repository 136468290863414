import configuredAxios from 'helpers/configuredAxios'
import queryString from 'query-string'

const fetchCompanyFilterService = (payload) => {
  const axios = configuredAxios('company')
  return axios.get(`/search?${queryString.stringify(payload)}`)
}

const fetchNews = (payload) => {
  const axios = configuredAxios('company')
  const { companyId, page, size = 6 } = payload
  return axios.get(`/news?company_id=${companyId}&page=${page}&size=${size}`)
}

const fetchBusinessInfo = (companyId) => {
  const axios = configuredAxios('company')
  return axios.get(`/${companyId}/business-info`)
}

export { fetchCompanyFilterService, fetchNews, fetchBusinessInfo }
