import configuredAxios from 'helpers/configuredAxios'
import queryString from 'query-string'

const fetchSearchSuggestionService = (payload) => {
  const axios = configuredAxios('job')
  return axios.get(`/search-suggestion?${queryString.stringify(payload)}`)
}

const fetchSearchRemoteSuggestionService = (payload) => {
  const axios = configuredAxios('job')
  return axios.get(`/search-remote-suggestion?${queryString.stringify(payload)}`)
}

export { fetchSearchSuggestionService, fetchSearchRemoteSuggestionService }
