export const startWorker = async () => {
    if (window?.SharedWorker) {
        window.imSharedWorker = new SharedWorker('/shared_worker.js', { type: 'module' })

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                window.imSharedWorker.port.postMessage({
                    type: 'active_page', desc: "IM在线推送", content: {
                        status: true
                    }
                });
            } else {
                window.imSharedWorker.port.postMessage({
                    type: 'active_page', desc: "IM在线推送", content: {
                        status: false
                    }
                });
            }
        });
    } else if ('serviceWorker' in navigator) {
        try {
            const serviceWorkerRegistration = await navigator.serviceWorker
                .register("/self_worker.js", { scope: "/", type: 'module' })
                .then((reg) => {
                    if (isChrome()) {
                        requestNotificationPermission();
                    }
                    return reg;
                })
                .catch((err) => {
                    return Promise.reject(err);
                });

            await serviceWorkerRegistration.update();

            if (typeof window !== "undefined" && "serviceWorker" in navigator) {
                navigator.serviceWorker.addEventListener("message", function ({ data }) {
                    const { type = null, content = {} } = data || {};
                    if (type === "jobseeker_redirect") {
                        const { chatId } = content;
                        window.location.href = location.origin + "/chat/" + chatId;
                    }
                });
            }

            navigator.serviceWorker.startMessages();
        } catch (e) {
            console.log({ firebaseError: e });
        }
    }

};
if (typeof window !== 'undefined') {
    startWorker();
}

function isChrome() {
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = winNav.userAgent.indexOf("OPR") > -1;
    const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");
    if (isIOSChrome) {
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        return true;
    } else {
        return false;
    }
}

function requestNotificationPermission() {
    if (!('Notification' in window)) {
        return;
    }

    if (Notification.permission === 'default') {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                //
            } else {
                //
            }
        }).catch(error => {
            console.error('请求通知权限时发生错误:Notification', error);
        });
    } else if (Notification.permission === 'granted') {
        //
    } else {
        //
    }
}