import configuredAxios from 'helpers/configuredAxios'

const deleteJobAlertService = (payload) => {
  const axios = configuredAxios('job', '')
  return axios.delete(`/job-alerts/${payload.jobAlertId}/delete`)
}

const deletePublicJobAlertService = (payload) => {
  const axios = configuredAxios('job')
  return axios.delete(`/public-job-alerts/${payload.id}/delete`, { data: payload })
}

export { deleteJobAlertService, deletePublicJobAlertService }
