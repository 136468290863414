'user client'
import { memo } from 'react'
import Link from 'components/Link'
import styles from 'app/index.module.scss'
import { getCookie, setCookie } from 'helpers/cookies'
import { chatSVG } from 'images/svg'
import { setJobViewCookiesTracker, viewJobSourceTracker } from 'components/ReportTracker/util'
import Image from 'next/image'
import { addEventTag } from 'helpers/utilities'

const JobDetail = ({ detail, langKey }: { detail: any; langKey: string }) => {
  const isLogin = getCookie('sessionid') ? true : false
  const { company = {}, salary_info = {}, chat_button = {}, job_tags = [] } = detail
  const company_tags = company?.company_tags || []

  const handleAddJobView = () => {
    setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.home,
      reco_from: detail?.reco_from ? detail?.reco_from : null,
      query_id: detail?.query_id ? detail?.query_id : null
    })

    if (isLogin) {
      addEventTag('hp_job_for_you_card_click')
    }

    if (!isLogin) {
      addEventTag('hp_popular_jobs_card_click')
    }
  }

  const tags = company_tags
    .map((tag) => {
      return `<i>${tag}</i>`
    })
    .join('<span>|</span>')

  return (
    <div className={styles.job_detail}>
      <div className={styles.job_info} onClick={() => handleAddJobView()}>
        <Link to={'/' + langKey + detail.job_url} external={true}>
          <div className={styles.job_titleWrapper}>
            <div className={styles.job_info_title} title={detail?.job_title}>
              {detail?.job_title}
            </div>
          </div>
          <div className={styles.transBox}>
            <div className={styles.job_info_salary}>
              {salary_info?.text ? `${salary_info?.text}` : null}
              {salary_info?.type_text ? (
                <span
                  style={{
                    color: '#2378e5',
                    fontSize: 16,
                    letterSpacing: '0.12px',
                    fontWeight: '400'
                  }}
                >{`[${salary_info?.type_text}]`}</span>
              ) : null}
            </div>
            <div className={styles.job_info_chat}>
              {chatSVG}
              {chat_button.text}
            </div>
          </div>
          <div className={styles.job_tags}>
            {job_tags.map((tag, index) => {
              const showLine = index < job_tags.length - 1 && job_tags.length > 1
              return (
                <div key={index}>
                  {tag}
                  {showLine && <span>|</span>}
                </div>
              )
            })}
          </div>
        </Link>
      </div>

      <Link className={styles.job_companyInfo} to={'/' + langKey + company.company_url}>
        <div className={styles.job_avatarWrapper}>
          <div className={styles.job_box}>
            <Image alt={company?.company_logo} src={company?.company_logo} width={24} height={24} />
            <div className={styles.job_companyInfo_name} title={company?.company_name}>
              {company?.company_name}
            </div>
          </div>
          <div
            className={styles.job_companyInfo_industry}
            title={company_tags.join(' | ')}
            dangerouslySetInnerHTML={{ __html: tags }}
          ></div>
        </div>
      </Link>
    </div>
  )
}

export default memo(JobDetail)
