import configuredAxios from 'helpers/configuredAxios'
import axios from 'axios'
import { fetchDeleteResumes } from '../jobs/fetchJobsCommunicated'

const uploadUserResumeService = (resume) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/upload-resume'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('file', resume)
  formData.append('filename', resume.name)
  formData.append('parse_resume', true)

  return axios.post(URL, formData, { headers })
}
const uploadVideoCover = (fileName) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/video-resumes/upload-video-cover'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }
  const formData = new FormData()
  formData.append('video_cover', fileName)
  return axios.post(URL, formData, {
    headers
  })
}
const uploadVideoResume = (url, id) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/upload-video-resume'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('url', url)
  formData.append('video_resume_id', id)
  formData.append('is_encrypted', 1)
  // formData.append('parse_resume', true)

  return axios.post(URL, formData, { headers })
}

const uploadVideoResumeV1 = (params) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/video_resume/upload_v1'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('video_resume', params.video_resume)
  formData.append('video_resume_id', params.video_cover_id)

  return axios.post(URL, formData, { headers })
}

const generatePresignedUrl = (fileName) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/video-resumes/generate-presigned-url/v3'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('file_name', encodeURIComponent(fileName))
  return axios.post(URL, formData, { headers })
}

const generateResumePresigned = (fileName) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/resume/generate-presigned-url/v3'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('file_name', encodeURIComponent(fileName))
  return axios.post(URL, formData, { headers })
}

const saveUploadResume = (url, { is_register_stage, old_resume_id } = {}) => {
  const axios = configuredAxios('jobseeker')
  const URL = '/resume/upload-save'
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('url', url)
  formData.append('is_encrypted', 1)
  if (is_register_stage) {
    formData.append('is_register_stage', is_register_stage)
  }
  if (old_resume_id) {
    formData.append('old_resume_id', old_resume_id)
  }

  return axios.post(URL, formData, { headers })
}

const uploadVideoToAmazonService = (url, file, onUploadProgress) => {
  return axios.post(url, file, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onUploadProgress && onUploadProgress(percentCompleted)
    }
  })
}

const getVideoResumeList = () => {
  const axios = configuredAxios('jobseeker')
  const URL = '/video-resumes'
  return axios.get(URL)
}
const getResumeList = () => {
  const axios = configuredAxios('jobseeker')
  const URL = '/resumes'
  return axios.get(URL)
}

const deleteVideoResume = (id) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/video-resume/${id}/delete`
  return axios.delete(URL)
}


const resumeTemplateList = () => {
  const axios = configuredAxios('resumeTemplate')
  const URL = '/public/list'
  return axios.get(URL)
}

const publicResumeClick = (data) => {
  const axios = configuredAxios('resumeTemplate')
  const URL = '/jobseeker/public-resume-click'
  return axios.post(URL, {
    ...data
  })
}


const parseResume = (resumeId) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/resume/${resumeId}/parse-detail`;
  return axios.get(URL)
}

const deleteResume = (id) => {
  return fetchDeleteResumes({ id })
}

const syncResumeStatus = (resumeId, statusId) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/resume/${resumeId}/update-sync-status`
  return axios.post(URL, {
    sync_status_id: statusId
  })
}

const getPreviewUrl = (filepath) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/resume/preview-by-filepath`
  return axios.post(URL, {
    filepath
  })
}

const compareOnLineResume = (resume_id) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/resume/${resume_id}/compare-with-online`
  return axios.post(URL)
}

const getSyncableContentService = (resume_id) => {
  const axios = configuredAxios('jobseeker')
  const URL = `/resume/${resume_id}/get-syncable-content`
  return axios.get(URL)
}


export {
  uploadUserResumeService,
  uploadVideoCover,
  uploadVideoResume,
  uploadVideoResumeV1,
  uploadVideoToAmazonService,
  generatePresignedUrl,
  generateResumePresigned,
  getVideoResumeList,
  deleteVideoResume,
  deleteResume,
  resumeTemplateList,
  publicResumeClick,
  saveUploadResume,
  getResumeList,
  parseResume,
  syncResumeStatus,
  getPreviewUrl,
  compareOnLineResume,
  getSyncableContentService,
}
