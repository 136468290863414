import axios from 'axios'
import { urlMap } from 'helpers/constants'
// import { getCookie } from 'helpers/cookies'

const uploadUserAvatarService = (file) => {

  const formData = new FormData()
  formData.append('avatar', file)
  formData.append('filename', file?.name)
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(`${urlMap.jobseeker}/upload-avatar`, formData, {
    headers: headers
  })
}



export { uploadUserAvatarService }
