import configuredAxios from 'helpers/configuredAxios'

const updateUserCompleteProfileService = (payload) => {
  const axios = configuredAxios('jobseeker')
  return axios.patch('/me', { ...payload })
}

const searchCompanyByDisplayName = (companyName) => {
  const axios = configuredAxios('company')
  return axios.get(`/search-company-by-legal-name-displany-name?company_name=${companyName}`)
}

const fetchAddBlacklistCompaniesService = (payload) => {
  const axios = configuredAxios('company')
  return axios.post(`/blacklist-companies/create`, payload)
}

const deleteBlackListCompaniesServie = ({ id }) => {
  const axios = configuredAxios('company')
  if (!id) return
  return axios.delete(`/${id}/blacklist-companies/delete`)
}

export {
  updateUserCompleteProfileService,
  searchCompanyByDisplayName,
  fetchAddBlacklistCompaniesService,
  deleteBlackListCompaniesServie
}
