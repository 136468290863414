"use client"
import React from "react"
import Link from 'components/Link'
import styles from 'app/index.module.scss'
import { addEventTag } from 'helpers/utilities'
const Tag = ({ href, title }) => {
  return <Link
    key={title}

    href={href}
    className={styles.tag}
    title={title}
    onClick={() => {
      addEventTag("hp_popular_jobs_click")
    }}
  >
    <div>{title}</div>
  </Link>
}

export default Tag