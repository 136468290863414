'use client'
import Link from 'components/Link'
import { addEventTag } from 'helpers/utilities'

const companiesTags = [
  'hp_top_companies_card_click',
  'hp_top_companies_job_card_click',
  'hp_top_companies_card_more_click'
]

const LinkToJob = (props) => {
  const { className, href, key, children } = props
  return (
    <Link
      className={className}
      key={key}
      href={href}
      external={true}
      onClick={() => addEventTag(companiesTags[1])}
    >
      {children}
    </Link>
  )
}

export default LinkToJob
