import { isCrispInitialed } from 'helpers/crisp';
import { useEffect, useState } from 'react'

let loaded = false

export const useSupportScript = loadSupport => {
  const [canUse, setCanUse] = useState(false);

  useEffect(() => {
    // the crisp does't export destroy method, so i hide/show it with display property

    const toggle = (displayValue = 'block') => {
      const element = document.getElementsByClassName('crisp-client')[0]
      // @ts-ignore
      element && (element.style.display = displayValue)
    }
    const loadSupportScript = () => {
      if (loaded) {
        // toggle('none')
        return
      }

      loaded = true
      // @ts-ignore
      window.$crisp = []
      // @ts-ignore
      window.CRISP_WEBSITE_ID = '93770f1a-c1a5-49c6-b449-26fbed046e69'
      function initial() {
        const d = document
        const s = d.createElement('script')
        s.src = 'https://client.crisp.chat/l.js'
        // @ts-ignore
        s.async = 1
        d.getElementsByTagName('head')[0].appendChild(s)
      }
      initial()
    }

    loadSupport && loadSupportScript()
    return () => {
      // const element = document.getElementsByClassName('crisp-client')[0]
      // element && (element.style.display = 'none')
      toggle('none')
    }
  }, [])

  useEffect(() => {
    let timer = -1;
    const checkCrispLoaded = () => {
      timer = window.setTimeout(() => {
        if (isCrispInitialed()) {
          setCanUse(true)
          return
        }
        checkCrispLoaded()
      }, 2000)
    }
    checkCrispLoaded()
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return canUse
}