import { getCookie } from "./cookies";
import { getCountryKey, getLang, getLanguageCode } from "./country";


const linkToHunt = (redirect, languageKey = '', countryKey = undefined) => {
    // getCountyKey ,getLangKey 获取 county 和 lang
    const county = countryKey || getCountryKey();
    const langKey = languageKey || getLang()
    const lang = getLanguageCode(langKey);
    const sessionid = getCookie('sessionid');
    if (sessionid) {
        // process.env.BOSSHUNT_URL
        return `${process.env.BOSSHUNT_URL}/keep-login?country=${county}&lang=${lang}&sessionid=${sessionid}&redirect=${redirect === 'boss' ? '' : redirect}`
    } else {
        // 跳转 process.env.BOSSHUNT_URL 参数 county lang redirect
        return `${process.env.BOSSHUNT_URL}/${redirect}?country=${county}&lang=${lang}`
    }
}
export default linkToHunt;