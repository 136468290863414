import configuredAxios from 'helpers/configuredAxios'

const updateJobAlertService = (payload) => {
  const axios = configuredAxios('job', '')
  return axios.put(`/job-alerts/${payload.updateJobAlertData.id}/update`, {
    ...payload.updateJobAlertData
  })
}

const updateJobAlertV2Service = (payload) => {
  const axios = configuredAxios('job', '')
  return axios.post(`/job-alerts/${payload.updateJobAlertData.id}/v2/update`, {
    ...payload.updateJobAlertData
  })
}

const updatePublicJobAlertService = (payload) => {
  const axios = configuredAxios('job')
  return axios.put(`/public-job-alerts/${payload.id}/update`, {
    ...payload
  })
}

export { updateJobAlertService, updateJobAlertV2Service, updatePublicJobAlertService }
