import configuredAxios from 'helpers/configuredAxios'
import { getCookie } from 'helpers/cookies'

const logoutService = (payload) => {
  const axios = configuredAxios('auth', '')
  const session = getCookie('sessionid')
  return axios.post('/log-out', { session, source: 'web', ...payload })
}

export { logoutService }
