import configuredAxios from 'helpers/configuredAxios'
import {
  cleanObjectValues,
  validateSourceAndDevice,
  viewJobSourceTracker
} from 'components/ReportTracker/util'

// job view tracker
const addJobViewService = (payload) => {
  if (!payload?.jobId) return
  const { status, jobId, ...params } = payload
  const newPayload = cleanObjectValues(params)
  const isValidValue = validateSourceAndDevice({
    obj: viewJobSourceTracker,
    source: newPayload?.source,
    device: newPayload?.device
  })
  if (!isValidValue) return
  const axios = configuredAxios('job')
  return axios.post(`/${jobId}/view`, newPayload)
}

const getShreCard = (id) => {
  const axios = configuredAxios('job')
  return axios.get(`/manage-jobs/shared-jobs/${id}`)
}

export { addJobViewService, getShreCard }
