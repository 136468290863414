// import CONFIG from 'shared/config'
import configuredAxios from 'helpers/configuredAxios'
// import { getCookie } from 'shared/helpers/cookies'
import { cache } from 'react'
const fetchJobDetailService = (cache ?? (a => a))((payload) => {
  const axios = configuredAxios('job', payload.status, false)
  return axios.get(`/${payload.jobId}`)
})
const fetchJobDetailServiceStatic = (cache ?? (a => a))((jobId) => {
  const axios = configuredAxios('job')
  return axios.get(`/${jobId}/static`)
})
const fetchJobSalary = (jobId) => {
  const axios = configuredAxios('job')
  return axios.get(`/${jobId}/salary`)
}

const fetchJobSaved = (cache ?? (a => a))((jobId) => {
  const axios = configuredAxios('job')

  return axios.get(`/${jobId}/is-saved`)
})
const fetchJobRecruiter = (jobId) => {
  const axios = configuredAxios('job')
  return axios.get(`/${jobId}/recruiter`)
}

export { fetchJobDetailService, fetchJobDetailServiceStatic, fetchJobSalary, fetchJobSaved, fetchJobRecruiter }
