// @ts-nocheck
import { applyMiddleware, createStore } from 'redux'
import rootReducer from 'store/reducers'
// import logger from 'redux-logger'
import rootSaga from 'store/sagas'
import createSagaMiddleware from 'redux-saga'

const bindMiddleware = (middleware) => {
  return applyMiddleware(...middleware)
}
export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const initialState = {}
  let store
  store = createStore(rootReducer, initialState, bindMiddleware([sagaMiddleware]))

  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}
export const store = configureStore()

