import configuredAxios from 'helpers/configuredAxios'

const postSaveJobService = (payload) => {
  const axios = configuredAxios('job', '')
  return axios.post('saved-jobs/create', { ...payload })
}


// const

// /companies/

export { postSaveJobService }