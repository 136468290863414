import { cond, T } from 'ramda'
import { convertSpecialCharacters } from 'helpers/utilities'

export const buildQuery = (location, searchValue) => {
  const onlyLoaction = () => !searchValue && !!location
  const locationAndSearch = () => searchValue && location
  const onlySearch = () => searchValue && !location
  const buildLocationAndOneValue = (location, value) => {
    const keyPath = convertSpecialCharacters(value?.value || value)
    return `/jobs-hiring/${encodeURIComponent(keyPath)}-jobs-in-${encodeURIComponent(
      location
    )}`
  }
  const buildOnlyOneValue = (value) => {
    const keyPath = convertSpecialCharacters(value?.value || value)
    return `/jobs-hiring/${encodeURIComponent(keyPath)}-jobs`
  }
  return (cond([
    [onlySearch, () => buildOnlyOneValue(searchValue)],
    [onlyLoaction, () => buildOnlyOneValue(location)],
    [locationAndSearch, buildLocationAndOneValue],
    [T, () => '/jobs-hiring/job-search']
  ])(location, searchValue) || '').toLowerCase()
}

export const buildRemoteQuery = (location, searchValue) => {
  const onlyLoaction = () => !searchValue && !!location
  const locationAndSearch = () => searchValue && location
  const onlySearch = () => searchValue && !location
  const buildLocationAndOneValue = (location, value) => {
    return `/remote-jobs-hiring/${encodeURIComponent(
      value?.value || value
    )}-jobs-in-${encodeURIComponent(location)}`
  }
  const buildOnlyOneValue = (value) => {
    return `/remote-jobs-hiring/${encodeURIComponent(value?.value || value)}-jobs`
  }
  return (cond([
    [onlySearch, () => buildOnlyOneValue(searchValue)],
    [onlyLoaction, () => buildOnlyOneValue(location)],
    [locationAndSearch, buildLocationAndOneValue],
    [T, () => '/remote-jobs-hiring/job-search']
  ])(location, searchValue) || '').toLowerCase()
}
