import configuredAxios from 'helpers/configuredAxios'
import { getCookie } from 'helpers/cookies'

const updateUserProfileService = (payload) => {
  const axios = configuredAxios('jobseeker', '')

  return axios.patch('/me', payload)
}

export { updateUserProfileService }
